import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from "next-auth/react"
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar';
import { RecoilRoot } from "recoil";
const App = ({ Component, pageProps }: AppProps) => {
    return (
      <SessionProvider session={pageProps.session}> 
        <NextNProgress 
            color="#778" 
            startPosition={0.3} 
            stopDelayMs={200}  
            height={2} 
            showOnShallow={true}
            options={{ showSpinner: false, easing: 'ease', speed: 500 }} />
        <Head>
            <title>Sistem Informasi Aset Kendaraan</title>
            <meta name="description" content="Sistem Informasi Aset Kendaraan" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
        </Head> 
        <RecoilRoot>
            <Component {...pageProps} />
        </RecoilRoot>
      </SessionProvider>
    );
  };
  export default App;
